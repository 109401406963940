<template>
  <div class="container">
    <div class="linear"></div>
    <h3 class="title">绑定易安捷SAP账号</h3>
    <van-form @submit="onSubmit">
      <van-field
          v-model="username"
          name="username"
          label="账号"
          clearable
          label-width="50px"
          left-icon="user-o"
          placeholder="请输入账号"
          :rules="[{ required: true, message: '' }]"
      />
      <van-field
          v-model="password"
          type="password"
          name="pwd"
          label="密码"
          clearable
          icon-prefix="iconfont iconmima"
          left-icon="eye-o"
          label-width="50px"
          placeholder="请输入密码"
          :rules="[{ required: true, message: '' }]"
      />
      <div style="margin: 16px;">
        <van-button block class="btn" native-type="submit">
          绑定
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {bindAccount, GetApprovalList} from "@/api/examiner";

export default {
  created() {
    this.$store.dispatch('changeLocation', '绑定账号')
    this.judge()
  },
  data() {
    return {
      username: '',
      password: '',
    }
  },
  methods: {
    onSubmit(params) {
      console.log('submit', params);
      params.openId = this.$store.state.info.openId;
      bindAccount(params).then(res=> {
        console.log(res);
        if(res.errcode == 200){
          this.$toast.success('绑定成功')
          setTimeout(()=>{
            this.$router.push({
              name: 'ApprovalList'
            })
          },1000)
        }else{
          this.$toast.fail(res.message);
        }
      })
    },
    async judge(){
      await GetApprovalList({openId:this.$store.state.info.openId}).then(res=>{
        if(res.errcode == 200){
          this.$router.push({
            name: 'ApprovalList',
            query: {
              data: res.rows
            }
          })
          this.show = false
        }
      })
    }
  },
};
</script>

<style scoped lang="scss">
.container {
  .linear {
    width: 100%;
    height: 10px;
    background: #F7F7F7;
  }
  .title {
    text-align: center;
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: normal;
    color: #333333;
    padding: 16px 0;
    margin: 0;
  }
  .btn {
    background: #02DBCA;
    border-radius: 6px;
    font-size: 18px;
    font-family: PingFang SC;
    color: #FFFFFF;
  }
}
</style>
